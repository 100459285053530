<template>
  <div class="do-page-my">
    <div class="card">
      <van-icon
        @click="router.push('/')"
        class="left-arrow"
        color="#FFFFFF"
        name="arrow-left"
      />
      <div class="user-wrap">
        <div class="user-logo">
          <img
            :src="
              $store.state.user.info.avatar ||
              require('@/assets/images/my/userlogo.png')
            "
          />
        </div>
        <div class="user-info">
          <div class="name">{{ $store.state.user.info.nickname || "-" }}</div>
          <div class="phone">
            <img :src="require('@/assets/images/my/iphone.png')" />
            {{ $ComJs.geTel($store.state.user.phone) || "-" }}
          </div>
        </div>
      </div>
    </div>
    <base-bscroll
      class="fn-list-wrap"
      :options="{
        bounce: {
          top: true,
          bottom: false,
        },
      }"
    >
      <div class="fn-list-content">
        <div class="do-com-cellitem" @click="toPath('/my/personal-center')">
          个人中心
          <div class="right">
            <van-icon name="arrow" color="rgba(197, 197, 200, 1)" />
          </div>
        </div>

        <div class="do-com-cellitem-group">
          <div class="do-com-cellitem" @click="toPath('/my/collect')">
            我的收藏
            <div class="right">
              <van-icon name="arrow" color="rgba(197, 197, 200, 1)" />
            </div>
          </div>
          <div class="do-com-cellitem" @click="toPath('/my/store-management')">
            店铺管理
            <div class="right">
              <van-icon name="arrow" color="rgba(197, 197, 200, 1)" />
            </div>
          </div>
        </div>
        <div class="do-com-cellitem-group">
          <div class="do-com-cellitem" @click="upPhone">
            更改手机号
            <div class="right">
              <van-icon name="arrow" color="rgba(197, 197, 200, 1)" />
            </div>
          </div>
          <!-- <div class="do-com-cellitem" @click="toPath('/my/third-party')">
            第三方账号绑定
            <div class="right">
              <van-icon name="arrow" color="rgba(197, 197, 200, 1)" />
            </div>
          </div> -->
        </div>
        <div class="do-com-cellitem-group">
          <!-- <div class="do-com-cellitem" @click="toPath('/my/message')">
            新消息通知
            <div class="right">
              <van-icon name="arrow" color="rgba(197, 197, 200, 1)" />
            </div>
          </div> -->
          <!-- <div class="do-com-cellitem" @click="clearCache">
            内存清理
            <div :style="{ marginRight: '0.1rem' }" class="right">
              {{ cacheSize }}
            </div>
          </div> -->
          <div class="do-com-cellitem" @click="toPath('/my/regulations')">
            关于客流易
            <div class="right">
              <van-icon name="arrow" color="rgba(197, 197, 200, 1)" />
            </div>
          </div>
        </div>
        <div class="do-com-cellitem-group">
          <div class="do-com-cellitem" @click="logout">
            退出登录
            <div class="right">
              <van-icon name="arrow" color="rgba(197, 197, 200, 1)" />
            </div>
          </div>
          <div class="do-com-cellitem" @click="toPath('/my/writeoff')">
            申请注销
            <div class="right">
              <van-icon name="arrow" color="rgba(197, 197, 200, 1)" />
            </div>
          </div>
        </div>

        <!-- <div @click="toPath('/my/writeoff')" class="fn-bottom">申请注销</div> -->
      </div>
    </base-bscroll>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { onMounted, ref, getCurrentInstance, onActivated } from "vue";
import { useStore } from "vuex";
import { Dialog, Toast } from "vant";
const { appContext } = getCurrentInstance();
const { $Hbuild, $ComJs } = appContext.config.globalProperties; //全局属性解构
const router = useRouter();
const store = useStore();
const cacheSize = ref(0);

onMounted(() => {
  // 获取app 缓存大小
  $Hbuild.getCacheSize().then((res) => {
    cacheSize.value = res;
  });
});

onActivated(() => {
  // 获取用户个人信息
  store.dispatch("getAccountInfo");
});
// 清理缓存
function clearCache() {
  if (cacheSize.value === "0M") {
    Toast("暂无内存需要清理~");
    return false;
  }
  Dialog.confirm({
    title: "提示",
    className: "cache-dialog",
    message: "确定清除缓存吗？",
    confirmButtonText: "确定",
  })
    .then(() => {
      if (navigator.userAgent.indexOf("Html5Plus") > -1) {
        $Hbuild.clearCache();
      } else {
        Toast("清除成功");
        let agreement = localStorage.getItem("agreement");
        // 用户退出不能清除用户的服务协议
        localStorage.clear();
        localStorage.setItem("agreement", agreement);
        store.dispatch("logout");
        router.push("/");
      }
    })
    .catch(() => {
      // on cancel
    });
}

function upPhone() {
  Dialog.confirm({
    title: "更改手机号",
    className: "cache-dialog",
    message: `当前手机号：${$ComJs.geTel(store.state.user.phone)}
    确定要修改吗？`,
    confirmButtonText: "确定",
  })
    .then(() => {
      toPath("/my/updata-phone");
    })
    .catch(() => {});
}

function toPath(path) {
  router.push(path);
}

function logout() {
  Dialog.confirm({
    className: "logout-dialog",
    title: "提示",
    message: "确定退出当前账号吗？",
  }).then(() => {
    store.dispatch("logout");
    router.push("/login");
  });
}
</script>

<style lang="scss">
.logout-dialog {
  .van-dialog__cancel {
    color: #0057ff !important;
  }
  .van-dialog__confirm {
    color: #323233 !important;
  }
}
.cache-dialog {
  .van-dialog__message {
    font-size: 0.28rem;
    font-weight: 400;
    color: #969799;
  }
  .van-dialog__cancel {
    color: #323233 !important;
  }

  .van-dialog__confirm {
    color: #0057ff !important;
  }
}
.do-page-my {
  @include fixedCom(0, 0, 0, 0);
  .card {
    display: flex;
    align-items: center;
    height: 4.1rem;
    background: url("../../assets/images/my/bg.png") center no-repeat;
    background-size: cover;
    padding: 0 0.28rem;
    .left-arrow {
      position: absolute;
      top: 1rem;
      font-size: 0.48rem;
    }
    .user-wrap {
      display: flex;
      position: relative;
      top: 0.46rem;
    }
    .user-logo {
      display: flex;
      margin-right: 0.24rem;
      height: 1.12rem;
      border-radius: 50%;
      img {
        border-radius: 50%;

        width: 1.12rem;
        height: 1.12rem;
      }
    }
    .user-info {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      height: 1.12rem;

      .name {
        font-weight: 500;
        color: #ffffff;
        font-size: 0.4rem;
      }
      .phone {
        width: 2.16rem;
        display: flex;
        align-items: center;
        font-size: 0.24rem;
        font-weight: 400;
        background: rgba(255, 255, 255, 0.25);
        color: #ffffff;
        border-radius: 24px;
        height: 0.48rem;
        padding: 0 0.16rem;
        position: relative;
        padding-left: 0.48rem;
        img {
          height: 0.4rem;
          position: absolute;
          left: 0.16rem;
          top: 50%;
          transform: translateY(-38%);
        }
      }
    }
  }
  .fn-list-wrap {
    @include fixedCom(0, 0, 0, 0);
    overflow: hidden;
    z-index: 100;
    height: calc(
      100vh - 3.6rem - $tabbar_hegiht - constant(safe-area-inset-bottom)
    );
    height: calc(100vh - 3.6rem - $tabbar_hegiht - env(safe-area-inset-bottom));
    // padding-bottom: calc($tabbar_hegiht + constant(safe-area-inset-bottom));
    // padding-bottom: calc($tabbar_hegiht + env(safe-area-inset-bottom));
    bottom: 0;
    top: 3.6rem;
    .fn-list-content {
      padding: 0 0.28rem;
    }
  }
  .fn-bottom {
    margin-top: 0.8rem;
    // position: fixed;
    // left: 50%;
    text-decoration: underline;
    font-size: 0.28rem;
    font-weight: 400;
    color: rgba(3, 5, 15, 0.25);
    text-align: center;
    // transform: translateX(-50%);
    // bottom: calc($tabbar_hegiht + constant(safe-area-inset-bottom));
    // bottom: calc($tabbar_hegiht + env(safe-area-inset-bottom));
    // z-index: 100;
  }
}
</style>
